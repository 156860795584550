<template>
  <div id="mapOld">

    <!-- =============== Button  - Left Panel =============== -->
    <div id="btn-group" :class="$vuetify.breakpoint.lgAndUp ? 'ma-5' : 'my-5'">

      <!-- (Search) -->
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
          v-bind="attrs"
          v-on="on"
          class="mb-3 ml-4"
          :small="$vuetify.breakpoint.lgAndUp ? false : true"
          fab
          dark
          color="white"
          @click="displaySearch =  !displaySearch; mapButton = false; infoButton = false;"
          >
            <v-icon color="text">
              mdi-magnify
            </v-icon>
           </v-btn>
        </template>
        <span>Search</span>
      </v-tooltip>

      <br>

      <!-- (Home) -->
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
            v-bind="attrs"
            v-on="on"
            class="mb-3 ml-4"
            :small="$vuetify.breakpoint.lgAndUp ? false : true"
            fab
            dark
            color="white"
            @click="home(); displaySearch = false"
            >
              <v-icon color="text">
                mdi-home
              </v-icon>
            </v-btn>
        </template>
        <span>Home</span>
      </v-tooltip>

      <br/>

      <!-- (Basemap) -->
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
            v-bind="attrs"
            v-on="on"
            class="mb-3 ml-4"
            :small="$vuetify.breakpoint.lgAndUp ? false : true"
            fab
            dark
            color="white"
            @click=" mapButton = !mapButton; infoButton = false; displaySearch = false; "
            >
              <v-icon dark color="text">
                mdi-map
              </v-icon>
            </v-btn>
        </template>
        <span>Basemap</span>
      </v-tooltip>

      <br/>

      <!-- (Information) -->
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
            v-bind="attrs"
            v-on="on"
            class="mb-3 ml-4"
            :small="$vuetify.breakpoint.lgAndUp ? false : true"
            fab
            dark
            color="white"
            @click=" infoButton = !infoButton; mapButton = false; displaySearch = false; "
            >
              <v-icon dark color="text">
                mdi-information-outline
              </v-icon>
            </v-btn>
        </template>
        <span>Information</span>
      </v-tooltip>

      <br/>

      <!-- (Point Of Interest) -->
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
            v-bind="attrs"
            v-on="on"
            :class="[poiState ? 'primary  mb-3 ml-4  font-weight-black justify-center align-center' : 'white text--text mb-3 ml-4 font-weight-black justify-center align-center' ,  $vuetify.breakpoint.lgAndUp ? 'text-h1' : 'text-h6']"
            :height="$vuetify.breakpoint.lgAndUp ? '56px' : '40px'"
            :width="$vuetify.breakpoint.lgAndUp ? '56px' : '40px'"
            icon 
            elevation="1"
            @click="displayPOI(); "
            >
              <v-icon dark :color="poiState ? 'white' : 'text'">
                mdi-map-marker
              </v-icon>
              </v-btn>
          </template>
        <span>Point Of Interest</span>
      </v-tooltip>
      
      <br/> 

      <!-- (Automated Station) -->
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
          v-bind="attrs"
          v-on="on"
          :class="automatedState ? 'primary white--text mb-3 ml-4  font-weight-black text-h6 justify-center align-center' : 'white text--text mb-3 ml-4 font-weight-black text-h6 justify-center align-center' "
          :small="$vuetify.breakpoint.lgAndUp ? false : true"
          @click="displayAutomated();"
          :height="$vuetify.breakpoint.lgAndUp ? '56px' : '40px'"
          :width="$vuetify.breakpoint.lgAndUp ? '56px' : '40px'"
          icon 
          elevation="1"
          >
            C
          </v-btn>
        </template>
        <span>Continuous Station</span>
      </v-tooltip>

      <br/>

      <!-- (Manual Station) -->
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
            v-bind="attrs"
            v-on="on"
            :class="manualState ? 'primary white--text mb-3 ml-4  font-weight-black text-h6 justify-center align-center' : 'white text--text mb-3 ml-4 font-weight-black text-h6 justify-center align-center' "
            :small="$vuetify.breakpoint.lgAndUp ? false : true"
            :height="$vuetify.breakpoint.lgAndUp ? '56px' : '40px'"
            :width="$vuetify.breakpoint.lgAndUp ? '56px' : '40px'"
            elevation="1"
            icon
            @click="displayManual();"
            >
              M
            </v-btn>
        </template>
        <span>Manual Station</span>
      </v-tooltip>

      <br/>

      <!-- (Investigative Study) -->
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
            v-bind="attrs"
            v-on="on"
            :class="isState ? 'primary white--text mb-3 ml-4  font-weight-black text-h6 justify-center align-center' : 'white text--text mb-3 ml-4 font-weight-black text-h6 justify-center align-center' "
            :height="$vuetify.breakpoint.lgAndUp ? '56px' : '40px'"
            :width="$vuetify.breakpoint.lgAndUp ? '56px' : '40px'"
            @click="displayIS(); "
            icon
            elevation="1"
            >
              IS
            </v-btn>
        </template>
        <span>Investigative Study</span>
      </v-tooltip>

      <br/>

      <!-- (Baseline Study) -->
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
            v-bind="attrs"
            v-on="on"
            :class="bsState ? 'primary white--text mb-3 ml-4  font-weight-black text-h6 justify-center align-center' : 'white text--text mb-3 ml-4 font-weight-black text-h6 justify-center align-center' "
            :height="$vuetify.breakpoint.lgAndUp ? '56px' : '40px'"
            :width="$vuetify.breakpoint.lgAndUp ? '56px' : '40px'"
            @click="displayBS(); "
            icon
            elevation="1"
            >
              BS
            </v-btn>
        </template>
        <span>Baseline Study</span>
      </v-tooltip>
      
    </div>



    <!-- =============== Card Content - Left Panel =============== -->
    <!-- (Basemap) -->
    <v-card :class="$vuetify.breakpoint.lgAndUp ? 'class-card-map' : 'class-card-map-mobile'" v-show="mapButton">
      <div class="justify-center align-center primary white--text text-center pa-3 card-header" >
        Map View Options
      </div>

      <template v-for="(item, index) in listMapViews">
        <v-row class="px-3" :key="index">
          <v-col cols="6" class="pa-4">
            <v-img
              :src="item.src"
              contain
              @click="changeBasemap(item.layer)"
            ></v-img>
          </v-col>

          <v-col cols="6" class="pa-4 caption" @click="changeBasemap(item.layer)">
            {{ item.name }}
          </v-col>
        </v-row>
      </template>
    </v-card>
    
    <!-- (Information) -->
    <v-card :class="$vuetify.breakpoint.lgAndUp ? 'class-card-info' : 'class-card-info-mobile'" v-show="infoButton">
      <div class="justify-center align-center primary white--text text-center pa-3 body-1 card-header" >
        Information
      </div>

      <div class="text-center pa-3 body-2">
        Station Status
      </div>

      <template v-for="(item, index) in station_status">
        <v-row class="px-5" :key="'B' + index">
          <v-col cols="6" class="px-4 py-1 text-center">
             <v-img
              height="60px"
              :src="item.img"
              contain
              @click="changeBasemap(item.layer)"
            ></v-img>
          </v-col>

          <v-col cols="6" class="'px-4 py-3 caption" >
            {{ item.text }}
          </v-col>
        </v-row>
      </template>

      <!-- <div class="text-center pa-3">WQI Class</div>

      <template v-for="(item, index) in listInfoViews">
        <v-row class="px-5" :key="'A' + index">
          <v-col cols="6" class="px-4 py-1 text-center">
            <v-icon>
              {{ item.icon }}
            </v-icon>
          </v-col>

          <v-col cols="6" class="px-4 py-1">
            {{ item.name }}
          </v-col>
        </v-row>
      </template> -->
    </v-card>

    <!-- (Search) -->
    <v-card 
    height="125px"
    width="250px"
    class="ma-0 pa-2 v-card-search "  
    v-show="displaySearch"
    >

      <v-card-subtitle>

        <v-row>
          <v-col class = "ma-0 pa-0" cols=4>State:</v-col>
          <v-col class = "ma-0 pa-0" cols=8>
             <v-select
                v-model="searchState"
                v-show="$vuetify.breakpoint.lgAndUp ? true : false"
                :items="stateList"
                label="State"  
                class="v-select-info"
                solo
                dense
                item-text="name"
                return-object
                @change="zoomToExtend(); searchStationList();"
              ></v-select>
          </v-col>
        </v-row>
        
        <v-row>
            <v-col class = "ma-0 pa-0" cols=4>Station:</v-col>
            <v-col class = "ma-0 pa-0" cols=8>
              <v-select
                  v-model="searchStation"
                  v-show="$vuetify.breakpoint.lgAndUp ? true : false"
                  :items="stationSearch"
                  label="Station"  
                  class="v-select-info"
                  solo
                  dense
                  item-text="STATION_ID_A"
                  return-object
                  @change="searchStations()"
                ></v-select>
            </v-col>
          </v-row>

      </v-card-subtitle>

    </v-card>
    
    <!-- (POI Information) -->
    <v-card
    class="mx-auto station-info-card"
    :max-width="$vuetify.breakpoint.xs ? '350' : '425'"
    min-width="350"
    max-height="90%"
    outlined
    v-show = "displayPOITable"
    >

        <v-card-title class="pa-2 primary">
          <h5>{{ selectedFeature.NAME }}</h5>

          <v-spacer></v-spacer>

          <v-btn
          dark
          icon
          x-small
          @click=" displayPOITable = !displayPOITable; "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>

          <v-list class="transparent">

            <v-list-item>
              <v-list-item-title>Coordinate:</v-list-item-title>

              <v-list-item-subtitle class="poi-description overflow-visible">
                {{ selectedFeature.LONGITUDE + ", " + selectedFeature.LATITUDE }}
              </v-list-item-subtitle>
            </v-list-item>

            <!-- <v-list-item>
                <v-list-item-title>Latitude:</v-list-item-title>

                <v-list-item-subtitle>
                    {{ selectedFeature.latitude }}
                </v-list-item-subtitle>
            </v-list-item> -->

            <v-list-item>
              <v-list-item-title>Description:</v-list-item-title>

              <v-list-item-subtitle class="poi-description overflow-visible">
                {{ selectedFeature.DESCRIPTION }}
              </v-list-item-subtitle>
            </v-list-item>


            <v-list-item>

              <v-list-item-title>Picture:</v-list-item-title>

              <v-list-item-subtitle class="poi-description overflow-visible">
                <template>
                  <v-row>

                    <v-col
                    class="d-flex child-flex"
                    cols="12"
                    v-if="selectedFeature.PICTURE_PATH !== ''"
                    >
                      <v-img
                      :src="selectedFeature.PICTURE_PATH"
                      aspect-ratio="1"
                      class="grey lighten-2"
                      >
                        <template v-slot:placeholder>
                          <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                          >
                            <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>

                    <v-col v-else>
                      None
                    </v-col>

                  </v-row>
                </template>
              </v-list-item-subtitle>

            </v-list-item>

          </v-list>

        </v-card-text>

    </v-card>



    <!-- =============== Card Content - Right Panel =============== -->
    <!-- (Details Popup after click on station icon) -->
    <v-card 
    :class=" $vuetify.breakpoint.lgAndUp ? 'mx-auto station-info-card' : 'mx-auto station-info-card-mobile' "
    :max-width="$vuetify.breakpoint.xs ? '320' : '700'"
    width="700"
    max-height="85%"
    outlined
    v-show="displayInformation"
    v-if="selectedFeature !== '' && selectedFeature.STATION_ID_A !== undefined"
    >

      <v-card-title class="pa-1 card-header ">

        <v-spacer></v-spacer>

        <v-tooltip right>

          <template v-slot:activator="{ on, attrs }">
            <v-btn
            v-bind="attrs"
            v-on="on"
            dark
            icon
            x-small
            @click="displayInformation = !displayInformation"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>

          Close

        </v-tooltip>
      

        <v-card-text v-if="selectedFeature !== ''" >
          <v-list-item three-line dark class="pl-0 pr-0">

            <v-list-item-avatar class="mr-2">
              <v-icon size = "45px">
                mdi-map-marker-multiple
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content class="text-h6 ">
                STATION: {{ selectedFeature.STATION_ID_A }}
            </v-list-item-content>

          
            <v-list-item-content>
              Location : {{ selectedFeature.LOCATION }} 
              <br>

              Last Updated:
              <br>
            
              {{
                new Date(selectedFeature.DATETIME_A).toLocaleDateString("en-GB") +
                  ", " +
                  new Date(selectedFeature.DATETIME_A).toLocaleTimeString()
              }}
            </v-list-item-content>

            <!-- ----------------COMMENT FOR THE TIME BEING---------------------- -->
            <!-- <v-list-item-content v-if="selectedFeature.PARAM_PH_CLASS === 'I'">
              <div class="reading-info-card class-I">
                <div class="text-h4" id="info_reading">
                  {{ selectedFeature.PARAM_PH }}
                </div>
                <div class="text-h6" id="info_class">
                  CLASS {{ selectedFeature.PARAM_PH_CLASS }}
                </div>
              </div>
            </v-list-item-content>
            <v-list-item-content
              v-else-if="selectedFeature.PARAM_PH_CLASS === 'II'"
            >
              <div class="reading-info-card class-II">
                <div class="text-h4" id="info_reading">
                  {{ selectedFeature.PARAM_PH }}
                </div>
                <div class="text-h6" id="info_class">
                  CLASS {{ selectedFeature.PARAM_PH_CLASS }}
                </div>
              </div>
            </v-list-item-content>
            <v-list-item-content
              v-else-if="selectedFeature.PARAM_PH_CLASS === 'III'"
            >
              <div class="reading-info-card class-III">
                <div class="text-h4" id="info_reading">
                  {{ selectedFeature.PARAM_PH }}
                </div>
                <div class="text-h6" id="info_class">
                  CLASS {{ selectedFeature.PARAM_PH_CLASS }}
                </div>
              </div>
            </v-list-item-content>
            <v-list-item-content
              v-else-if="selectedFeature.PARAM_PH_CLASS === 'IV'"
            >
              <div class="reading-info-cardclass-IV">
                <div class="text-h4" id="info_reading">
                  {{ selectedFeature.PARAM_PH }}
                </div>
                <div class="text-h6" id="info_class">
                  CLASS {{ selectedFeature.PARAM_PH_CLASS }}
                </div>
              </div>
            </v-list-item-content>
            <v-list-item-content
              v-else-if="selectedFeature.PARAM_PH_CLASS === 'V'"
            >
              <div class="reading-info-card class-V">
                <div class="text-h4" id="info_reading">
                  {{ selectedFeature.PARAM_PH }}
                </div>
                <div class="text-h6" id="info_class">
                  CLASS {{ selectedFeature.PARAM_PH_CLASS }}
                </div>
              </div>
            </v-list-item-content>
            <v-list-item-content v-else>
              <div class="reading-info-card class-NA">
                <div class="text-h4" id="info_reading">
                  {{
                    selectedFeature.PARAM_PH == "-1"
                      ? "NA"
                      : selectedFeature.PARAM_PH
                  }}
                </div>
                <div class="text-h6" id="info_class">
                  CLASS
                  {{
                    selectedFeature.PARAM_PH_CLASS == "-1" ||
                    selectedFeature.PARAM_PH_CLASS == null
                      ? "NA"
                      : selectedFeature.PARAM_PH_CLASS
                  }}
                </div>
              </div>
            </v-list-item-content> -->
          </v-list-item>
        </v-card-text>
      </v-card-title>

      <template>
        <v-expansion-panels v-model="extend_panel" accordion>
          <v-expansion-panel >
            <v-expansion-panel-header>PARAMETER</v-expansion-panel-header>
            <v-expansion-panel-content>
              <template class="dashboard_table">
                <v-data-table
                  item-key="name"
                  class="elevation-1 mb-2 station-info-table"
                  :items-per-page="-1"
                  :disable-pagination="true"
                  :disable-filtering="true"
                  :hide-default-footer="true"
                  :hide-default-header="true"
                >
                  <template v-slot:header>
                    <thead class="headers-class white--text">
                      <tr style="background: #0081BA">
                        <th style="color: white;"></th>
                        <th style="color: white;">Parameter</th>
                        <th style="color: white;">Value & Unit</th>
                        <th style="color: white;">Sub Index</th>
                      </tr>
                    </thead>
                  </template>

                  <template v-slot:body>
                    <tbody class="text-center">
                      <tr
                        v-for="(nestedItem,
                        nestedKey) in selectedStationInfo.parameter"
                        :key="nestedKey"
                      >
                        <template>
                           <td v-if="nestedKey < 3" class="text-start">
                             <span :class="'dot-'+nestedItem.class"></span>
                          </td>
                          <td v-else class="text-start"></td>
                          <td class="text-start">{{ nestedItem.name }}</td>
                          <td
                            v-if="nestedItem.value != null"
                            class="text-start"
                          >
                            {{ nestedItem.value + " " + nestedItem.unit }}
                          </td>
                          <td v-else class="text-start">NA</td>
                          <td
                            v-if="nestedKey < 3 && nestedItem.si != null"
                            class="text-start"
                          >
                            {{ nestedItem.si }}
                          </td>
                          <td v-else-if="nestedKey < 3" class="text-start">
                            NA
                          </td>
                          <td v-else class="text-start"></td>

                          <!-- <td class="text-start">  {{ nestedItem.si}} </td> -->
                        </template>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel v-if="station_id !== ''">
            <v-expansion-panel-header>STATION DETAILS</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list class="transparent pt-0">
                <v-list-item dense>
                  <v-list-item-title>Station ID: </v-list-item-title>

                  <v-list-item-subtitle>
                    {{ selectedFeature.STATION_ID_A }}
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-title>Station Location: </v-list-item-title>

                  <v-list-item-subtitle>
                    {{ selectedFeature.LOCATION }} PERAK
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-title>Longitude: </v-list-item-title>

                  <v-list-item-subtitle>
                    {{ selectedFeature.LONGITUDE }}
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-title>Latitude: </v-list-item-title>

                  <v-list-item-subtitle>
                    {{ selectedFeature.LATITUDE }}
                  </v-list-item-subtitle>
                </v-list-item>

                <v-list-item dense>
                  <v-list-item-title>Sonde Depth (m): </v-list-item-title>

                  <v-list-item-subtitle>
                    {{ selectedFeature.DEPTH == null ? '-' : selectedFeature.DEPTH }}
                  </v-list-item-subtitle>
                </v-list-item>

                <v-list-item dense>
                  <v-list-item-title>Battery (V): </v-list-item-title>

                  <v-list-item-subtitle>
                    {{ selectedFeature.BATTERY == null ? '-' : selectedFeature.BATTERY }}
                  </v-list-item-subtitle>
                </v-list-item>


                <v-list-item dense>
                  <v-list-item-title>Station Picture: </v-list-item-title>
                    <v-list-item-subtitle v-if = "selectedFeature.STATION_PICTURE == ''  &&   selectedFeature.DEPLOYMENT_PICTURE == '' &&  selectedFeature.UPSTREAM_PICTURE == '' &&  selectedFeature.DOWNSTREAM_PICTURE == ''" > None</v-list-item-subtitle>
                </v-list-item>

                <!-- DISABLE BECAUSE NO STATION INFO DEKAT API ------------- KENA UPDATE FARA -->
                <v-list-item dense>
                
                  <v-row>
                    <v-col
                      class="d-flex child-flex"
                      cols="6"
                      v-if="selectedFeature.STATION_PICTURE !== ''"
                    >
                      <v-img
                        :src="
                          selectedFeature.STATION_PICTURE.replace(
                            'sarawakdir',
                            'publicdir'
                          )
                        "
                        aspect-ratio="1"
                        class="grey lighten-2"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                    <v-col
                      class="d-flex child-flex"
                      cols="6"
                      v-if="selectedFeature.DEPLOYMENT_PICTURE !== ''"
                    >
                      <v-img
                        :src="
                          selectedFeature.DEPLOYMENT_PICTURE.replace(
                            'sarawakdir',
                            'publicdir'
                          )
                        "
                        aspect-ratio="1"
                        class="grey lighten-2"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                    <v-col
                      class="d-flex child-flex"
                      cols="6"
                      v-if="selectedFeature.UPSTREAM_PICTURE !== ''"
                    >
                      <v-img
                        :src="
                          selectedFeature.UPSTREAM_PICTURE.replace(
                            'sarawakdir',
                            'publicdir'
                          )
                        "
                        aspect-ratio="1"
                        class="grey lighten-2"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                    <v-col
                      class="d-flex child-flex"
                      cols="6"
                      v-if="selectedFeature.DOWNSTREAM_PICTURE !== ''"
                    >
                      <v-img
                        :src="
                          selectedFeature.DOWNSTREAM_PICTURE.replace(
                            'sarawakdir',
                            'publicdir'
                          )
                        "
                        aspect-ratio="1"
                        class="grey lighten-2"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
                </v-list-item> 



                <!-- -------------------------------------------------------------------- -->
                
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </v-card>

  </div>
</template>

<script>
import { bus } from '@/main';

import "ol/ol.css";

import Map from "ol/Map";
import View from "ol/View";
import { defaults as defaultControls, ScaleLine } from "ol/control";
import {
  Tile as TileLayer,
  Vector as VectorLayer,
  Group as GroupLayer,
  Image as ImageLayer
} from "ol/layer";
import { OSM, Vector as VectorSource, ImageArcGISRest} from "ol/source";
import { Fill, Stroke, Circle, Style, Icon } from "ol/style";
import Feature from "ol/Feature";
import { fromLonLat } from "ol/proj";
import { Point } from "ol/geom";
import Overlay from "ol/Overlay";
import { extend as Extend, Extent } from 'ol/extent';


// Axios for fetching data
import axios from "axios";


export default {

  data: () => ({

    // Left Panel
    items1: [],
    listMapViews: [
      {
        src: require("@/assets/mapviewer/googlestreetmap.jpg"),
        name: "Google Street Map",
        layer: "googleStreetMap",
      },
      {
        src: require("@/assets/mapviewer/googlesatelitemap.jpg"),
        name: "Google Satellite Map",
        layer: "googleSateliteMap",
      },
      {
        src: require("@/assets/mapviewer/topo_map.jpg"),
        name: "Topographic Map",
        layer: "topographicMap",
      },
      {
        src: require("@/assets/mapviewer/imagery_map.jpeg"),
        name: "Imagery Map",
        layer: "imageryMap",
      },
      {
        src: require("@/assets/mapviewer/streetsmap.jpg"),
        name: "Street Map",
        layer: "streetMap",
      },
    ],

    listInfoViews: [
      { icon: require("@/assets/mapviewer/googlestreetmap.jpg"), name: "Google Street Map" },
      { icon: require("@/assets/mapviewer/googlestreetmap.jpg"), name: "Google Satellite Map" },
      { icon: require("@/assets/mapviewer/googlestreetmap.jpg"), name: "Topographic Map" },
    ],
    mapButton: false,
    infoButton: false,
    displaySearch: false,
    contiStationDetails: [],
    station_status: [
      {
        text: "Active",
        img: require("@/assets/mapviewer/station_icon_green.svg"),
      },
      {
        text: "Inactive",
        img: require("@/assets/mapviewer/station_icon_grey.svg"),
      },
      {
        text: "Transmission Delay",
        img: require("@/assets/mapviewer/station_icon_orange.svg"),
      },
      {
        text: "Manual Station",
        img: require("@/assets/mapviewer/manual_station_icon.svg"),
      },
    ],
    selectedFeature: {},
    displayInformation: true,
    station_id: "",
    stationInfoList: [],
    selectedStationInfo: {},
    listParamDisplayed: [
      "pH",
      "DO_SAT",
      "TSS",
      "DO_CON",
      "TEMPERATURE",
      "CONDUCTIVITY",
      "SALINITY",
      "TURBIDITY",
    ],
    paramContentDetail: [],
    searchStation: "",
    searchStation2: "",

    pointOfInterestDetails: [],
    manualStationDetails: [],
    poiState: false,
    manualState: false,
    automatedState: true,
    isState: false,
    bsState: false,
    extend_panel: 0,
    searchState: "",
    stationSearch: [],
    displayPOITable: false,
    stateList: [
      {
        name : "All State",
        extent: [94.32000875515988, -5.522322250111256, 124.30006603850293, 9.239271903589454],
      },
      {
        name : "Johor",
        extent: [ 101.75131845249587, 0.9648397067421169, 105.03228642718585, 2.936787745410824 ]
      },
      {
        name : "Kedah",
        extent: [ 99.18237832805765, 4.868878185804961, 102.27289842282222, 6.726362040066922 ]
      },
      {
        name : "Kelantan",
        extent: [ 100.48662704604496, 4.22756904514186, 104.12036122778761, 6.411538851615798 ]
      },
      {
        name : "Melaka",
        extent: [ 101.87990747321184, 2.0140964128687537, 102.74770036995118, 2.535662765815993 ]
      },
      {
        name : "Negeri Sembilan",
        extent: [ 101.23197559573036, 2.311199386709852, 103.00206901676957, 3.375072084281202 ]
      },
      {
        name : "Pahang",
        extent: [ 101.32164875634818, 2.1705664481059337, 104.54518359319592, 4.107995660502555 ]
      },
      {
        name : "Pulau Pinang",
        extent: [ 99.95865819539875, 5.07536846147747, 100.82096527482321, 5.593637693947454 ],
      },
      {
        name : "Perak",
        extent:  [ 99.19376717126754, 3.107394301718, 103.93947091127147, 6.221346965852233 ]
      },
      {
        name : "Perlis",
        extent: [ 99.79007647773318, 6.206554668803961, 100.66984732426624, 6.73532008458425 ]
      },
      {
        name : "Sabah",
        extent: [ 113.46796387834475, 3.3194714263250154, 120.12397229526154, 7.31990753065537 ]
      },
      {
        name : "Sarawak",
        extent: [ 95.57959422793664, -7.150926608313, 123.04048056572617, 10.8678762617912 ]
      },
      {
        name : "Selangor",
        extent: [ 100.2290452924926, 2.622573633094058, 102.47532843128863, 4.096500314502931 ]
      },
      {
        name : "Terengganu",
        extent: [ 100.844627539974, 3.4080101841022903, 104.7366502371485, 5.9618093908617436 ]
      },
      {
        name : "Kuala Lumpur",
        extent: [ 101.25701986175493, 2.820524480563135, 102.11091098655321, 3.3808157858404355 ]
      },
      {
        name : "Labuan",
        extent: [ 115.04727368923399, 5.183495225394548, 115.39458171451717, 5.4113857153737825 ]
      },
      {
        name : "Putrajaya",
        extent: [ 101.39872332436629, 2.763936225159858, 101.9138387898793, 3.101935658763293 ]
      },
    ],


    // Map Declaration
    map: null,
    view: null,
    extent:  [ 101.03601339379257, 5.632160927069602, 101.06845224016779, 5.648133180907534 ],
    baseMapLayer: {
      topographicMap: new TileLayer({
        title: "Topographic Map",
        source: new OSM({
          url:
            "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
          crossOrigin: "anonymous",
        }),
        visible: false,
        type: "base",
      }),
       boundaryLayer: new ImageLayer({
        title: "Topographic Map",
        source: new ImageArcGISRest({
          url:
            "https://eqmp.scienotech.com.my/arcgis/rest/services/DEMARCATION/STATE/MapServer",
        }),
        visible: true,
        type: "layer",
      }),
      imageryMap: new TileLayer({
        title: "Imagery Map",
        source: new OSM({
          // "url" : 'http://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}',
          url:
            "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
          // "url": 'https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
          crossOrigin: "anonymous",
        }),
        visible: true,
        type: "base",
      }),
      streetMap: new TileLayer({
        title: "OSM",
        source: new OSM({
          url:
            "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
          crossOrigin: "anonymous",
        }),
        visible: false,
        type: "base",
      }),
      googleStreetMap: new TileLayer({
        title: "Google Street",
        source: new OSM({
          url: "https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}",
          // "url": 'http://mt1.googleapis.com/vt?x={x}&y={y}&z={z}',
          crossOrigin: "anonymous",
        }),
        visible: false,
        type: "base",
      }),
      googleSateliteMap: new TileLayer({
        title: "OSM",
        source: new OSM({
          url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
          crossOrigin: "anonymous",
        }),
        visible: false,
        type: "base",
      }),
    },
    pointStyle: {
      default: new Style({
        image: new Circle({
          fill: new Fill({
            color: "#3381ff",
          }),
          stroke: new Stroke({
            color: "#0242ab",
            width: 1.25,
          }),
          radius: 8,
        }),
      }),
      selected: new Style({
        image: new Circle({
          fill: new Fill({
            color: "#00ffff",
          }),
          stroke: new Stroke({
            color: "#003300",
            width: 1.25,
          }),
          radius: 8,
        }),
      }),

      //LEFT BUTTONS DECLARATION
      // listMapViews: [
      //   {
      //     path:
      //   }
      // ]
    },

  }),

  mounted() {

    this.initiateMap();
    this.getParamInfo();
    this.getPOIjson();
    // this.checkAdmin();

    this.getCurrentMode();

    

  },

  methods: {

    // General ====================
    getCurrentMode(){

      console.log(localStorage.clickButtonAMIS);

      if(localStorage.clickButtonAMIS == undefined){

        bus.$emit("trigger - header",{
          focus: "clickButtonAMIS",
          a: this.automatedState,
          m: this.manualState,
          is: this.isState,
          bs: this.bsState,
        })

        let data = {
          a: this.automatedState,
          m: this.manualState,
          is: this.isState,
          bs: this.bsState,
        }

        localStorage.setItem("clickButtonAMIS",JSON.stringify(data));

      }
      else{

        let currentMode = JSON.parse(localStorage.clickButtonAMIS)

        console.log(currentMode);

        this.automatedState = currentMode.a
        this.manualState = currentMode.m
        this.isState = currentMode.is
        this.bsState = currentMode.bs

      }

      console.log(this.manualState);

      

    },

    getParamInfo() {
      axios
        .get(this.globalUrl + "mqm2/miqims/params", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          this.paramDetails = response.data;

          // console.log(this.paramDetails);
          this.getStationInfo();
 

          this.genParamList();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    genParamList() {
      for (let j = 0; j < this.listParamDisplayed.length; j++) {
        for (let i = 0; i < this.paramDetails.length; i++) {
          if (this.listParamDisplayed[j] == this.paramDetails[i].reading) {
            // console.log("listParamDisplayed", this.listParamDisplayed[j], this.paramDetails[i].reading)

            Object.keys(this.paramDetails[i]).forEach((key) => {
              if (this.paramDetails[i][key] === null) {
                this.paramDetails[i][key] = "";
              }
            });

            let paramContent = {
              name: this.listParamDisplayed[j],
              fullName: this.paramDetails[i].fullname,
              unit: this.paramDetails[i].unit,
            };

            this.paramContentDetail.push(paramContent);
          }
        }
      }
    },

    getStationInfo() {
      axios
        .get(this.globalUrl + "mqm2/miqims/reading", {
        // .get("https://run.mocky.io/v3/98873f4d-ebf7-44ff-9565-4fa2674af35c", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          this.contiStationDetails = response.data;

          this.addMapMarker();

          this.getStationInfoList();

          // this.addMapMarker();

          // console.log(this.contiStationDetails);

          // this.genTableHeader();

          // let stationData = response.data;
          // this.stationList = [];
          // for(let i = 0; i < stationData.length; i++){
          //   this.stationList.push(stationData[i].STATION_ID_A);

          // for(let j = 0; j < this.headers.length; j++){
          //   if(this.headers[j].value == stationData[i].STATION_ID_A){
          //     this.headers[j].name = stationData[i].LOCATION;
          //     this.headers[j]['last_date'] = stationData[i].DATETIME;
          //   }
          // }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getStationInfoList() {
      let listParam = [];
      let keys;
      for (let i = 0; i < this.contiStationDetails.length; i++) {
        keys = Object.keys(this.contiStationDetails[i]);
      }

      //get all properties that include param string
      const allParamKeys = keys.filter((keys) => {
        return keys.includes("PARAM");
      });

      //get all paramaters without sub index and class
      let paramWithoutSiAndClass = allParamKeys.filter((allParamKeys) => {
        return !allParamKeys.includes("SI") && !allParamKeys.includes("CLASS");
      });

      //get all parameters name only
      let parameters = allParamKeys.filter((paramWithoutSiAndClass) => {
        return (
          !paramWithoutSiAndClass.includes("SI") &&
          !paramWithoutSiAndClass.includes("CLASS")
        );
      });

      parameters.forEach(function(param, index) {
        // console.log("huww", parameters[index]);
        parameters[index] = parameters[index].replace("PARAM_", "");
      });

      // console.log("paramDetails", JSON.stringify(this.paramDetails))
      // console.log("parameterd", parameters)
      // console.log("contiStationDetails", JSON.stringify(this.contiStationDetails))

      for (let i = 0; i < this.contiStationDetails.length; i++) {
        let paramList = [];

        // for(let k = 0; k < parameters.length ; k++){

        let paramObj = {};

        for (let j = this.listParamDisplayed.length - 1; j > -1; j--) {
          for (let m = 0; m < this.paramDetails.length; m++) {
            if (this.listParamDisplayed[j] == this.paramDetails[m].reading) {

              // console.log( "AAAA",  this.contiStationDetails[i].STATION_ID_A , this.listParamDisplayed[j], "PARAM_SI_" + this.listParamDisplayed[j].toUpperCase(),  this.contiStationDetails[i], this.contiStationDetails[i]["PARAM_SI_" + this.listParamDisplayed[j].toUpperCase()]);


              // console.log("eee" , this.contiStationDetails[i][
              //     "PARAM_" + this.listParamDisplayed[j].toUpperCase() + "_CLASS"])

              paramObj = {
                name: this.paramDetails[m].fullname,
                value: this.contiStationDetails[i][this.listParamDisplayed[j]],
                unit: this.paramDetails[m].unit,
                si: this.contiStationDetails[i][
                  "PARAM_SI_" + this.listParamDisplayed[j].toUpperCase()
                ],
                class: this.contiStationDetails[i][
                  "PARAM_" + this.listParamDisplayed[j].toUpperCase() + "_CLASS"]
              };

              // console.log( "param_si_"+(this.listParamDisplayed[j]).toLowerCase(),this.contiStationDetails[i]["param_si_"+(this.listParamDisplayed[j]).toLowerCase()]);

              if (parameters.includes(this.listParamDisplayed[j].toLowerCase()))
                paramList.push(paramObj);
              else paramList.unshift(paramObj);
            }
          }
        }

        let stationInfoObj = {
          stationId: this.contiStationDetails[i].STATION_ID_A,
          location: this.contiStationDetails[i].LOCATION,
          datetime: this.contiStationDetails[i].DATETIME_A,
          longitude: this.contiStationDetails[i].LONGITUDE,
          latitude: this.contiStationDetails[i].LATITUDE,
          station_picture: this.contiStationDetails[i].STATION_PICTURE,
          deployment_picture: this.contiStationDetails[i].DEPLOYMENT_PICTURE,
          upstream_picture: this.contiStationDetails[i].UPSTREAM_PICTURE,
          downstream_picture: this.contiStationDetails[i].DOWNSTREAM_PICTURE,
          parameter: paramList,
        };

        this.stationInfoList.push(stationInfoObj);
      }
    },



    // Create Map and Set Basemap ====================
    initiateMap() {
      var source = new VectorSource();
      var vector = new VectorLayer({
        source: source,
      });

      var baseMap = new GroupLayer({
        layers: [
          this.baseMapLayer,
          // this.baseMapLayer.topographicMap,
          // this.baseMapLayer.imageryMap,
          // this.baseMapLayer.streetMap,
          // this.baseMapLayer.googleStreetMap,
          // this.baseMapLayer.googleSateliteMap,
        ],
      });

      this.map = new Map({
        controls: defaultControls({
          zoom: false,
        }),
        // .extend([
        //     new ScaleLine({
        //         units: 'metric',
        //     }),
        // ]),
        target: "mapOld",
        layers: [baseMap],
        view: new View({
          projection: "EPSG:4326",
          zoom: 1,
        }),
      });

      this.view = this.map.getView();
      this.view.fit(this.extent);

      // this.map.on("click", function (evt) {
      //     console.log("l");
      //     // this.clickMapssss();
      // },this.clickMapssss());

      this.onClickMap();

      this.map.on('moveend', this.onMoveEnd)

      // const feature = this.forEachFeatureAtPixel(
      //     evt.pixel,
      //     (feature) => feature
      //   );

      //  this.map.on("click", (evt) => {
      //       this.clickMapssss(evt);
      //   });
    },

    onMoveEnd(evt) {

              // console.log(evt.pixel);
              // console.log(this.view);

                const map = evt.map;
                // console.log("extent2", map.getView().calculateExtent())
                // console.log(fromLonLat(evt.coordinate, 'EPSG:3857', 'EPSG:4326'));
    },

    addborderLayer() {
      
        this.map.addLayer(this.baseMapLayer.boundaryLayer);
        
        //  let extent = myLayer.getSource().getExtent();
        //  console.log("extentttt", this.map);
          // this.map.getView().fit(extent, map.getSize());
       
    },

    searchStations2() {
      this.zoomStation(
        this.searchStation2.LONGITUDE,
        this.searchStation2.LATITUDE - 0.007
      );
      this.station_id = this.searchStation2.STATION_ID_A;
      this.selectedFeature = this.searchStation2;
      this.getSelectedStationInfo(this.station_id);
      this.displayInformation = true;
    },

    searchStations() {

      this.extend_panel = 0,
      this.zoomStation(
        this.searchStation.LONGITUDE,
        this.searchStation.LATITUDE
      );
      this.station_id = this.searchStation.STATION_ID_A;
      this.selectedFeature = this.searchStation;
      this.getSelectedStationInfo(this.station_id);
      this.displayInformation = true;
    },

    searchStationList() {

      this.displaySearch = false;

      this.stationSearch = [];


      if (this.searchState.name.toUpperCase() == "ALL STATE")
           this.stationSearch = this.contiStationDetails;
      else {
        // console.log(this.contiStationDetails.length);
        for(let i = 0; i < this.contiStationDetails.length; i++){
            
          if (this.contiStationDetails[i].STATE_NAME.toUpperCase() == this.searchState.name.toUpperCase() ){
            this.stationSearch.push(this.contiStationDetails[i])
          }
            
        }
      }



    },

    home() {
      this.displayInformation = false;
      this.displayPOITable = false;
      this.infoButton = false;
      this.mapButton = false;
      this.view = this.map.getView();
      this.view.fit(this.extent, {
        duration: 500,
      });
    },

    zoomStation(longitude, latitude) {
      this.map.setView(
        new View({
          projection: "EPSG:4326", //or any projection you are using
          center: [longitude, latitude], //zoom to the center of your feature
          zoom: 15, //here you define the levelof zoom
        })
      );
    },

    changeBasemap(layer) {
      // console.log("layer", layer);

      for (let i = 0; i < this.listMapViews.length; i++) {
        let layerName = this.listMapViews[i].layer;
        // console.log(layer,layerName);
        if (layer == layerName) this.baseMapLayer[layerName].setVisible(true);
        else this.baseMapLayer[layerName].setVisible(false);
      }
    },

    displayPOI() {

      this.poiState = !this.poiState;

      let that = this;


      let mapLayer = this.map.getLayers();

      for ( let i in this.map.getLayers().getArray()) {

        let el = mapLayer.getArray()[i];
          if(el.get("name") ===  "Point Of Interest" )
            el.setVisible(that.poiState)

      }
    },

    displayAutomated() {

      this.automatedState = !this.automatedState;
      this.isState = false;
      this.bsState = false;

      let that = this;

      setTimeout(() => {
        
        bus.$emit("trigger - header",{
          focus: "clickButtonAMIS",
          a: this.automatedState,
          m: this.manualState,
          is: this.isState,
          bs: this.bsState,
        })

        let data = {
          a: this.automatedState,
          m: this.manualState,
          is: this.isState,
          bs: this.bsState,
        }

        localStorage.setItem("clickButtonAMIS",JSON.stringify(data));

      }, 500)
      


      let mapLayer = this.map.getLayers();

      for ( let i in this.map.getLayers().getArray()) {

        let el = mapLayer.getArray()[i];
          if(el.get("name") ===  "Automated" )
            el.setVisible(that.automatedState)

      }

      for (let element of document.getElementsByClassName("overlayclass")){
      
          this.automatedState == true ? element.style.display = "block" : element.style.display="none";

      }


    },

    displayManual() {

      this.manualState = !this.manualState;
      this.isState = false;
      this.bsState = false;

      setTimeout(() => {
        
        bus.$emit("trigger - header",{
          focus: "clickButtonAMIS",
          a: this.automatedState,
          m: this.manualState,
          is: this.isState,
        })

        let data = {
          a: this.automatedState,
          m: this.manualState,
          is: this.isState,
        }

        localStorage.setItem("clickButtonAMIS",JSON.stringify(data));

      }, 500)

      let that = this;


      let mapLayer = this.map.getLayers();

      for ( let i in this.map.getLayers().getArray()) {

        let el = mapLayer.getArray()[i];
          if(el.get("name") ===  "Manual" )
            el.setVisible(that.manualState)

      }
    },

    displayIS() {

      this.isState = !this.isState;
      this.automatedState = false;
      this.manualState = false;
      this.bsState = false;

      setTimeout(() => {
        
        bus.$emit("trigger - header",{
          focus: "clickButtonAMIS",
          a: this.automatedState,
          m: this.manualState,
          is: this.isState,
        })

        let data = {
          a: this.automatedState,
          m: this.manualState,
          is: this.isState,
        }

        localStorage.setItem("clickButtonAMIS",JSON.stringify(data));

      }, 500)

      let that = this;


      let mapLayer = this.map.getLayers();

      for ( let i in this.map.getLayers().getArray()) {

        let el = mapLayer.getArray()[i];
          if(el.get("name") ===  "IS" )
            el.setVisible(that.isState)

      }
    },

    displayBS(){

      this.bsState = !this.bsState;
      this.automatedState = false;
      this.manualState = false;
      this.isState = false;

      setTimeout(() => {
        
        bus.$emit("trigger - header",{
          focus: "clickButtonAMIS",
          a: this.automatedState,
          m: this.manualState,
          is: this.isState,
          bs: this.bsState,
        })

        let data = {
          a: this.automatedState,
          m: this.manualState,
          is: this.isState,
          bs: this.bsState,
        }

        localStorage.setItem("clickButtonAMIS",JSON.stringify(data));

      }, 500)

    },

    zoomToExtend(){

      let extent2 = this.searchState.extent ;

      this.view = this.map.getView();
      this.view.fit(extent2, {
        duration: 500,
      });

    },

    onClickMap() {
      let that = this;

      this.map.on("click", function(evt) {
        that.infoButton = false;
        that.mapButton = false;
        that.displaySearch = false;
        // console.log("map", that.map.getView().getCenter())
        // let yeyeye = that.map.getView().getCenter()
        

//         --------------------------TESTING -------------------------------------
//         //Create an empty extent that we will gradually extend
//           var extent = this.Extent.createEmpty();

//           this.map.getLayers().forEach(function(layer) {
//               //If this is actually a group, we need to create an inner loop to go through its individual layers
//               if(layer instanceof this.Group) {
//                   layer.getLayers().forEach(function(groupLayer) {
//                       //If this is a vector layer, add it to our extent
//                       if(layer instanceof this.Vector)
//                          Extend(extent, groupLayer.getSource().getExtent());
//                   });
//               }
//               else if(layer instanceof this.Vector)
//                 Extend(extent, layer.getSource().getExtent());
//           });

//           //Finally fit the map's view to our combined extent
//           this.map.getView().fit(extent, this.map.getSize());

// -----------------------------------------------------------------------------------------------------

        let feature = this.forEachFeatureAtPixel(
          evt.pixel,
          (feature) => feature
        );

        // console.log("feature = ", feature);
        if (feature !== undefined) {

          that.selectedFeature = feature.get("description");
          // let stationtype = feature.get("name");

          // console.log("staa", stationtype); cv 

          
          // if( stationtype == "Point Of Interest"){
          //   that.displayPOITable = true;
          //   console.log("hehhehe");
          //   that.zoomStation(that.selectedFeature.longitude,that.selectedFeature.latitude);
          // }
          // else {

            if (feature.get("name") == "Point Of Interest")
                that.displayPOITable = true;
            else
                that.displayInformation = true;
            that.extend_panel = 0;


            that.station_id = that.selectedFeature.STATION_ID_A;


            // console.log(that);
            that.getSelectedStationInfo(that.selectedFeature.STATION_ID_A);


            that.zoomStation(that.selectedFeature.LONGITUDE, that.selectedFeature.LATITUDE );

          // }

    
          
        }
      });
    },

    getSelectedStationInfo(statID) {
      this.stationPicture = [];

      // console.log("stat", statID)

      for (let i = 0; i < this.stationInfoList.length; i++) {
        // console.log(this.stationInfoList[i].stationId, statID);
        if (this.stationInfoList[i].stationId == statID) {
          this.selectedStationInfo = this.stationInfoList[i];
        }
      }

      let keys = Object.keys(this.selectedStationInfo);
      //get all properties that include param string
      let arrParam = keys.filter((keys) => {
        return keys.includes("picture");
      });

      for (let i = 0; i < arrParam.length; i++) {
        let obj = {
          src: this.selectedStationInfo[arrParam[i]],
        };

        this.stationPicture.push(obj);
      }

    },



    // Create layer and load layer ====================
    addMapMarker() {
      // this.addborderLayer();

      console.log(this.contiStationDetails);
      
      for (let i = 0; i < this.contiStationDetails.length; i++) {
        var marker = new Feature({
          geometry: new Point([
            this.contiStationDetails[i].LONGITUDE,
            this.contiStationDetails[i].LATITUDE,
          ]),
          description: this.contiStationDetails[i],
          name: "Automated",
        });
        var vectorSource = new VectorSource({
          features: [marker],
        });

        var markerVectorLayer = new VectorLayer({
          name: "Automated",
          source: vectorSource,
          style: new Style({
            image: new Icon({
              crossOrigin: "anonymous",
              src:
                this.contiStationDetails[i].GREEN == "TRUE"
                  ? this.station_status[0].img
                  : this.contiStationDetails[i].GREY == "TRUE" 
                  ? this.station_status[1].img
                  : this.contiStationDetails[i].YELLOW == "TRUE"
                  ? this.station_status[2].img
                  : "",
              scale: 1.5,
            }),
          }),
        });
        this.map.addLayer(markerVectorLayer);
      }
      // this.addMapOverlayReading();

      this.addMapOverlay();
    },

    addMapOverlay() {
      for (let i = 0; i < this.contiStationDetails.length; i++) {
        // console.log("wuwuwu", this.contiStationDetails[i].STATION_ID_A);

        // let elementID = "reading_" + this.contiStationDetails[i].STATION_ID_A;

        // let div = document.createElement("DIV");
        // div.setAttribute("id", elementID);

        // div.setAttribute("class", "reading-div");

        // console.log("here",this.contiStationDetails[i].PARAM_PH_CLASS);

        // // div.innerHTML = this.contiStationDetails[i].PARAM_PH;
        // if (this.contiStationDetails[i].PARAM_PH_CLASS == "I")
        //   div.setAttribute("class", "reading-div overlayclass class-I");
        // else if (this.contiStationDetails[i].PARAM_PH_CLASS == "II")
        //   div.setAttribute("class", "reading-div overlayclass class-II");
        // else if (this.contiStationDetails[i].PARAM_PH_CLASS == "III")
        //   div.setAttribute("class", "reading-div overlayclass class-III");
        // else if (this.contiStationDetails[i].PARAM_PH_CLASS == "IV")
        //   div.setAttribute("class", "reading-div overlayclass class-IV");
        // else if (this.contiStationDetails[i].PARAM_PH_CLASS == "V")
        //   div.setAttribute("class", "reading-div overlayclass class-V");
        // else if (this.contiStationDetails[i].PARAM_PH_CLASS == null)
        //   div.setAttribute("class", "reading-div overlayclass class-NA");

        // div.innerHTML =
        //   this.contiStationDetails[i].PARAM_PH_CLASS == null
        //     ? "NA"
        //     : this.contiStationDetails[i].PARAM_PH_CLASS;

        // document.getElementById("map").appendChild(div);

        // let overlay = new Overlay({
        //   element: document.getElementById(elementID),
        //   id: this.contiStationDetails[i].STATION_ID_A,
        //   positioning: "top-center",
        //   offset: [-18, -52],
        // });

        // overlay.setPosition([
        //   this.contiStationDetails[i].LONGITUDE,
        //   this.contiStationDetails[i].LATITUDE,
        // ]);

        // this.map.addOverlay(overlay);

        let labelStationID = "label_" + this.contiStationDetails[i].STATION_ID_A;

        let divLabel = document.createElement("DIV");

        divLabel.setAttribute("id", labelStationID);
        divLabel.setAttribute("ref", labelStationID);

        divLabel.setAttribute("class", "level-stationID overlayclass");
        divLabel.setAttribute("style", "background:white;font-weight: bold;opacity: 70%;color: black !important;");

        divLabel.innerHTML = this.contiStationDetails[i].STATION_ID_A;

        document.getElementById("map").appendChild(divLabel);

        // console.log("this is element >>> ",document.getElementById(labelStationID));
 
        let overlayLabel = new Overlay({
          element: document.getElementById(labelStationID),
          id: "label__" + this.contiStationDetails[i].STATION_ID_A,
          positioning: "top-center",
          offset: [0, -55],
        });

        overlayLabel.setPosition([
          this.contiStationDetails[i].LONGITUDE,
          this.contiStationDetails[i].LATITUDE,
        ]);

        let myInterval = setInterval(() => {
            this.map.addOverlay(overlayLabel);
            clearInterval(myInterval);
        }, 1000);

        
      }
    },

    getPOIjson() {
       axios
        .get(this.globalUrl + "mqm2/miqims/interest", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          this.pointOfInterestDetails = response.data;
          this.addPOIMarker();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    
    addPOIMarker() {
      for (let i = 0; i < this.pointOfInterestDetails.length; i++) {
        var marker = new Feature({
          geometry: new Point([
            this.pointOfInterestDetails[i].LONGITUDE,
            this.pointOfInterestDetails[i].LATITUDE,
          ]),
          description: this.pointOfInterestDetails[i],
          name: "Point Of Interest",
        });
        var vectorSource = new VectorSource({
          features: [marker],
        });

        var POIMarker = new VectorLayer({
          name: "Point Of Interest",
          source: vectorSource,
          style: this.pointStyle.selected,
          visible: false,
        });
        this.map.addLayer(POIMarker);
      }

      // this.getManualJson();

    },
   



    // For what MIra? - Nuzul -
    displayStationByType(state, stationType) {

      this.state = !this.state;

      this.curState = this.state;



      let that = this;


      let mapLayer = this.map.getLayers();

      for ( let i in this.map.getLayers().getArray()) {

        let el = mapLayer.getArray()[i];
          if(el.get("name") === stationType )
            el.setVisible(that.curState);
          else if (el.get("name") == undefined)
            el.setVisible(true);
          else
            el.setVisible(false);

      }


      for (let element of document.getElementsByClassName("overlayclass")){
        if( stationType == "Automated"){
            this.curState == true ? element.style.display = "block" : element.style.display="none";
        }
        else
            element.style.display="none";

      }
     
    },

    

    

    // checkAdmin(){
    //   this.thisuser = this.$store.getters.user.idUserAccess;
    //   bus.$emit('admincanuse', this.thisuser);
    // }

  },
  
};
</script>

<style scoped>

#mapOld {
  position: fixed;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

#btn-group {
  position: absolute;
  z-index: 5;
  /* width: 150px; */
}

.dashboardBtn {
  position: absolute;
  right: 20px;
  z-index: 5;
}

.class-card-map {
  position: absolute;
  top: 25px;
  left: 119px;
  z-index: 5;
  width: 210px;
  height: 450px;
  /* text-align: center; */
}

.class-card-map-mobile {
  position: absolute;
  top: 14px;
  left: 80px;
  z-index: 5;
  width: 210px;
  height: 450px;
  /* text-align: center; */
}

.class-card-info {
  position: absolute;
  top: 25px;
  left: 119px;
  z-index: 5;
  width: 210px;
  height: 410px;
  /* text-align: center; */
}

.class-card-info-mobile {
  position: absolute;
  top: 14px;
  left: 80px;
  z-index: 5;
  width: 210px;
  height: 410px;
  /* text-align: center; */
}

.station-info-card-mobile {
  position: absolute;
  top: 300px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 10;
  overflow: auto;
  -webkit-user-drag: element; 
}

.station-info-card {
  position: absolute;
  z-index: 5;
  right: 10px;
  top: 30px;
  overflow: auto;
  -webkit-user-drag: element;
}

.headers_class > tr > th {
  color: #276173 !important;
}

.station-info-table > .v-data-table__wrapper {
  background: #a5a5a5 !important;
}

.mobileSearch {
  position: absolute;
  top: 50px;
}
#map::v-deep .reading-div {
  position: absolute;
  z-index: 1;
  width: 35px;
  border: 1px solid white;
  text-align: center;
  /* border-image: radial-gradient(red 69%,#0000 70%) 84.5% fill/100%;
  clip-path: polygon(-41% 0,50% 91%, 141% 0); */
}

#map::v-deep .class-I {
  background-color: #2d7ef8 !important;
}

#map::v-deep .class-II {
  background-color: #7cde6b !important;
}

#map::v-deep .class-III {
  background-color: #ffdd03 !important;
}

#map::v-deep .class-IV {
  background-color: #ffa834 !important;
}

#map::v-deep .class-V {
  background-color: #ff7e71 !important;
}

#map::v-deep .class-NA {
  background-color: #cccccc !important;
}



#map::v-deep .reading-info-card {
  padding: 15px;
  text-align: center;
  border: 1px solid whitesmoke;
}

#map::v-deep .v-text-field.v-text-field--solo .v-input__control {
  min-height: 50px;
}


#map::v-deep  .v-select-info {
  width: 150px;
  min-height: 10px !important;
}

#map::v-deep .v-input__slot {     
  min-height: 20px !important;   
} 

#map::v-deep .v-label {     
  min-height: 30px !important;   
} 

#map::v-deep  .v-label{
  font-size: 10px;
}

#map::v-deep  .v-card-search {
  position: absolute;
  left: 107px;
  top: 20px;
  z-index: 5;
  line-height: 120px;
  border-radius: 10px;
  padding: 0px;
  /* background: none; */

}

#map::v-deep .dot-I {
  height: 15px;
  width: 15px;
  background-color: #2d7ef8;
  border-radius: 50%;
  display: inline-block;
}

#map::v-deep .dot-II {
  height: 15px;
  width: 15px;
  background-color: #7cde6b;
  border-radius: 50%;
  display: inline-block;
}

#map::v-deep .dot-II {
  height: 15px;
  width: 15px;
  background-color: #ffdd03;
  border-radius: 50%;
  display: inline-block;
}

#map::v-deep .dot-IV {
  height: 15px;
  width: 15px;
  background-color: #ffa834;
  border-radius: 50%;
  display: inline-block;
  animation: blinker 2s linear infinite;
}

#map::v-deep .dot-V {
  height: 15px;
  width: 15px;
  background-color: #FF3823;
  border-radius: 50%;
  display: inline-block;
  animation: blinker 2s linear infinite;
}

#map::v-deep .dot-NA {
  height: 15px;
  width: 15px;
  background-color: #cccccc;
  border-radius: 50%;
  display: inline-block;
}

#map::v-deep  .blinking {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

</style>
